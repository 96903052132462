import { Role } from "./role";
import { Currency } from "./currency";

export interface AccountingItem {
  id: number;
  authority: Role;
  createdAt: string;
  email: string;
  userTelegram: string;
  leadTelegram: string;
  members?: number;
  level?: AccountingLevel;
  tags?: string[];
  feedback: boolean;
  wrote: boolean;
  comment?: string;
  commentFromBot?: string;
  statistics: [
    {
      currency: Currency;
      lastWeekTrx: number;
      lastMonthTrx: number;
      prevWeekTrx: number;
      prevMonthTrx: number;
      weekCards: number;
      monthCards: number;
    }
  ];
}

export enum AccountingLevel {
  TOP = "Top",
  MIDDLE = "Middle",
  LOW = "Low",
}
