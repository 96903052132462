export default function getPartnerLogo(
  name: string,
  type: "svg" | "png" = "png"
): string {
  if (type === "svg" && !["proxys"].includes(name)) {
    return require(`@/assets/img/partners/transparent/${name}.svg`);
  }

  return require(`@/assets/img/partners/${name}.png`);
}
