import { Getter } from "vuex";
import { RootState } from "@store/types";
import { PartnerCategoryState, PartnerCategoryGetters } from "../types";

const partnerCategoriesLoading: Getter<PartnerCategoryState, RootState> = (
  state
) => {
  const getter: PartnerCategoryGetters["partnerCategoriesLoading"] = (
    langCode
  ) => {
    return state.loading[langCode] ?? false;
  };

  return getter;
};

export default partnerCategoriesLoading;
