import { Action } from "vuex";
import { RootState } from "@store/types";
import { MessageState } from "../types";

const markReadMessagesInFeed: Action<MessageState, RootState> = async (
  { commit, state },
  { ids }: { ids: string[] }
) => {
  if (!ids.length) {
    return;
  }

  commit(
    "setFeedMessagesList",
    state.feed.list.map((message) => ({
      ...message,
      closedAt: ids.includes(message.id)
        ? new Date().toString()
        : message.closedAt,
    }))
  );

  const { closeFeedMessages } = await import("@/api/feed/closeFeedMessages");

  closeFeedMessages({ ids });
};

export default markReadMessagesInFeed;
