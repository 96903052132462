import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { AuthState } from "../types";

const signUp: Action<AuthState, RootState> = async ({ commit }, payload) => {
  const {
    data: { token, expires },
  } = await axios.post("/auth/signup", payload);

  commit("setUserInfo", { token, expires });
};

export default signUp;
