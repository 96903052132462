import { PartnerCategoryIcon } from "@/types/partner";

const partnerCategoryIconMetaMap = new Map<
  PartnerCategoryIcon,
  {
    code: PartnerCategoryIcon;
    iconName: string;
    color: string;
  }
>([
  [
    PartnerCategoryIcon.TEAM,
    {
      code: PartnerCategoryIcon.TEAM,
      iconName: "$team",
      color: "#4E96FA",
    },
  ],
  [
    PartnerCategoryIcon.EARTH,
    {
      code: PartnerCategoryIcon.EARTH,
      iconName: "$earth",
      color: "#45DF82",
    },
  ],
  [
    PartnerCategoryIcon.TOOL,
    {
      code: PartnerCategoryIcon.TOOL,
      iconName: "$tool",
      color: "#FFC008",
    },
  ],
  [
    PartnerCategoryIcon.CELEBRATE,
    {
      code: PartnerCategoryIcon.CELEBRATE,
      iconName: "$celebrate",
      color: "#CC83E5",
    },
  ],
  [
    PartnerCategoryIcon.SERVER_NETWORK,
    {
      code: PartnerCategoryIcon.SERVER_NETWORK,
      iconName: "$serverNetwork",
      color: "#243143",
    },
  ],
  [
    PartnerCategoryIcon.TARGET,
    {
      code: PartnerCategoryIcon.TARGET,
      iconName: "$target",
      color: "#FF515F",
    },
  ],
]);

const partnerCategoryIconMetaItems = Array.from(
  partnerCategoryIconMetaMap.values()
);

export { partnerCategoryIconMetaMap, partnerCategoryIconMetaItems };
