import { Action } from "vuex";
import { RootState } from "@store/types";
import { TeamState } from "../types";

const fetchTeamInviteAction: Action<TeamState, RootState> = async () => {
  const { fetchTeamInvite } = await import("@/api/team");

  return fetchTeamInvite();
};

export default fetchTeamInviteAction;
