import axios from "axios";
import { TeamMember } from "@/types/team";

export async function updateTeamAutorefill(
  payload: Pick<TeamMember, "email"> & {
    settings: TeamMember["autoRefillSettings"];
  }
): Promise<void> {
  await axios.post("/team/autorefill/update", payload);
}
