export default function scrollToEl(id: string): void {
  const sectionEl = document.getElementById(id);

  if (!sectionEl) return;

  let marginTop = 0;
  const homePageEl = document.getElementById("home-page");

  if (homePageEl) {
    marginTop = parseFloat(window.getComputedStyle(homePageEl).paddingTop) || 0;
  }

  window.scrollTo({
    top: sectionEl.getBoundingClientRect().top + window.scrollY - marginTop,
    behavior: "smooth",
  });

  // window.history.replaceState(null, document.title, `/#${id}`);
}
