import { Mutation } from "vuex";
import { LangCode } from "@/types/lang";
import { PartnerCategory } from "@/types/partner";
import { PartnerCategoryState } from "../types";

const setPartnerCategoriesList: Mutation<PartnerCategoryState> = (
  state,
  {
    langCode,
    value,
  }: {
    langCode: LangCode;
    value: Map<number, PartnerCategory>;
  }
) => {
  state.list = {
    ...state.list,
    [langCode]: value,
  };
};

export default setPartnerCategoriesList;
