import axios from "axios";
import { ReferralProfit } from "@/types/referral";

interface Response {
  profit: ReferralProfit;
}

export async function fetchReferralProfit(): Promise<Response> {
  const { data } = await axios.get<Response>("/referral/profit");

  return data;
}
