async function startApp() {
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    window.location.href = "/";

    return;
  }

  const baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_API_URL
      : "/multicards-api/";

  try {
    const response = await fetch(`${baseURL.replace(/\/$/, "")}/api/doc`, {
      headers: {
        "X-Auth-Token": userToken,
      },
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    const spec = await response.json();

    window.ui = window.SwaggerUIBundle({
      dom_id: "#swagger-ui",
      spec,
    });
  } catch {
    window.location.href = "/";
  }
}

startApp();
