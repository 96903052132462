import axios from "axios";
import { Message, MessageType } from "@/types/message";

export async function searchFeedMessages(payload: {
  types: MessageType[];
  searchAfter: string;
  searchClosed?: boolean;
}) {
  const { data } = await axios.post<Message[]>(
    "/feed/messages/search",
    payload
  );

  return data;
}
