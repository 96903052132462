import { isEmpty } from "lodash";
import decodeHtmlEntities from "./decodeHtmlEntities";

export default function getPartnerLink(value: string) {
  if (isEmpty(value)) {
    return;
  }

  return decodeHtmlEntities(value);
}
