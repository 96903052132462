import axios from "axios";

export async function applyPromocode({
  promocode,
}: {
  promocode: string;
}): Promise<void> {
  await axios.post("/profile/promocode", {
    promocode,
  });
}
