import axios from "axios";
import { CardType, CardBatchIssue } from "@/types/card";

export async function batchIssueCard(payload: {
  note?: string;
  numCards: number;
  depositAmount?: number;
  totalLimit?: number | null;
  dailyLimit?: number | null;
  autoRefillThreshold?: number | null;
  autoRefillAmount?: number | null;
  cardType: CardType;
  bankId: number;
  cardGroupId?: number;
  captcha?: string;
}): Promise<CardBatchIssue> {
  const { data } = await axios.post<CardBatchIssue>(
    "/card/batchIssue",
    payload
  );

  return data;
}
