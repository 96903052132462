import axios from "axios";
import { PartnerCategory } from "@admin/types/partner";

export const fetchPartnerCategories = async () => {
  const {
    data: { items },
  } = await axios.get<{ items: PartnerCategory[] }>(
    "/admin/partners/category/list"
  );

  items.sort((a, b) => a.displayOrder - b.displayOrder);

  return items;
};
