import * as accounting from "./accounting";
import * as bank from "./bank";
import * as exchangeLog from "./exchangeLog";
import * as log from "./log";
import * as login from "./login";
import * as network from "./network";
import * as news from "./news";
import * as partners from "./partners";
import * as promocode from "./promocode";
import * as referral from "./referral";
import * as transactionMonitoring from "./transaction-monitoring";
import * as card from "./card";

export const AdminApi = {
  accounting,
  bank,
  exchangeLog,
  log,
  login,
  network,
  news,
  partners,
  promocode,
  referral,
  transactionMonitoring,
  card,
};
