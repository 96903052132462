import { Getter } from "vuex";
import { RootState } from "@store/types";
import { PartnerCategoryState, PartnerCategoryGetters } from "../types";

const partnerCategoriesList: Getter<PartnerCategoryState, RootState> = (
  state
) => {
  const getter: PartnerCategoryGetters["partnerCategoriesList"] = (
    langCode
  ) => {
    return state.list[langCode] ?? new Map();
  };

  return getter;
};

export default partnerCategoriesList;
