import { Action } from "vuex";
import { RootState } from "@store/types";
import { TeamState } from "../types";

const refreshTeamInviteAction: Action<TeamState, RootState> = async () => {
  const { refreshTeamInvite } = await import("@/api/team");

  return refreshTeamInvite();
};

export default refreshTeamInviteAction;
