import { MutationTree } from "vuex";
import { PartnerCategoryState } from "../types";
import setPartnerCategoriesList from "./setPartnerCategoriesList";
import setPartnerCategoriesLoading from "./setPartnerCategoriesLoading";

const mutations: MutationTree<PartnerCategoryState> = {
  setPartnerCategoriesList,
  setPartnerCategoriesLoading,
};

export default mutations;
