enum PartnerLogo {
  ADS_POWER = "ads-power",
  KEITARO = "keitaro",
  NET_3S = "3snet",
  CPA_MAFIA = "cpa-mafia",
  BANZAI = "banzai",
  BINOM = "binom",
  CLOACKING_HOUSE = "cloacking-house",
  CPAGETTI = "cpagetti",
  HOAX_TECH = "hoax-tech",
  LEADBIT = "leadbit",
  LGAMING = "lgaming",
  SHAKES = "shakes",
  MOSTBET_PARTNERS = "mostbet-partners",
  PROXYMA = "proxyma",
  DR_CASH = "dr-cash",
  MAC = "mac",
  MORELOGIN = "morelogin",
  CLOUD_FILTER = "cloud-filter",
  CANDINAL_CONF = "candinal-conf",
  PROXYMUS = "proxymus",
  MONEY_SAFE = "money-safe",
  REVENUELAB = "revenuelab",
  KAMINARI = "kaminari",
  AFF_WEBSITE = "aff-website",
  PROXYS = "proxys",
}

enum PartnerCategoryIcon {
  TEAM = "team",
  EARTH = "earth",
  TOOL = "tool",
  CELEBRATE = "celebrate",
  SERVER_NETWORK = "serverNetwork",
  TARGET = "target",
}

interface PartnerCategory {
  id: number;
  icon: PartnerCategoryIcon;
  title: string;
  displayOrder: number;
}

interface Partner {
  id: number;
  logo: PartnerLogo;
  title: string;
  content: string;
  displayOrder: number;
  buttonText: string;
  link: string;
  rating: number;
  badge?: string;
  promoCode?: string;
  promoCodeText?: string;
  categoryId: number | null;
}

export { PartnerLogo, PartnerCategoryIcon };

export type { Partner, PartnerCategory };
