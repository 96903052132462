enum EOAuthGoogleEvent {
  LOGIN = "oauth2_login",
  SIGNUP = "oauth2_signup",
}

interface IOAuthGoogleLoginData {
  token: string;
  expires: number;
  email: string;
}

interface IOAuthGoogleSignUpData {
  email: string;
  first_name: string;
  last_name: string;
}

type TOAuthGoogleResponse =
  | {
      event: EOAuthGoogleEvent.LOGIN;
      data: IOAuthGoogleLoginData;
    }
  | {
      event: EOAuthGoogleEvent.SIGNUP;
      data: IOAuthGoogleSignUpData;
    };

export { EOAuthGoogleEvent };

export { IOAuthGoogleLoginData, IOAuthGoogleSignUpData, TOAuthGoogleResponse };
