import { Mutation } from "vuex";
import { PartnerCategoryState } from "../types";
import { LangCode } from "@/types/lang";

const setPartnerCategoriesLoading: Mutation<PartnerCategoryState> = (
  state,
  { langCode, value }: { langCode: LangCode; value: boolean }
) => {
  state.loading = {
    ...state.loading,
    [langCode]: value,
  };
};

export default setPartnerCategoriesLoading;
