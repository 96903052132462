import { Action } from "vuex";
import { RootState } from "@store/types";
import { WarningState } from "../types";

const hideWarnings: Action<WarningState, RootState> = async (
  {
    commit,
    state: {
      feed: { list: feedWarnings },
    },
  },
  { ids }: { ids: number[] }
) => {
  const newFeedWarnings = feedWarnings.filter(({ id }) => !ids.includes(id));

  commit("setFeedWarningList", newFeedWarnings);

  ids.forEach((id) => {
    import("@/api/warning/hideWarning").then(({ hideWarning }) =>
      hideWarning({
        warningId: id,
      })
    );
  });
};

export default hideWarnings;
