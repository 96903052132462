import { Currency } from "./currency";

export enum CashFlowType {
  CARD_ISSUE = "CARD_ISSUE",
  TRANSACTION_FEE = "TRANSACTION_FEE",
  INT_TRANSACTION_FEE = "INT_TRANSACTION_FEE",
  FUNDS_DEPOSIT = "FUNDS_DEPOSIT",
  FUNDS_TRANSFER = "FUNDS_TRANSFER",
  INCOMING_FUNDS_TRANSFER = "INCOMING_FUNDS_TRANSFER",
  CARD_DEPOSIT = "CARD_DEPOSIT",
  TRANSACTION_REFUND = "TRANSACTION_REFUND",
  TRANSACTION_REFUND_TO_BALANCE = "TRANSACTION_REFUND_TO_BALANCE",
  TRANSACTION_REFUNDED_FEE = "TRANSACTION_REFUNDED_FEE",
  FUNDS_EXCHANGE = "FUNDS_EXCHANGE",
  CARD_CLOSE = "CARD_CLOSE",
  DECLINE_FEE = "DECLINE_FEE",
  CARD_WITHDRAW = "CARD_WITHDRAW",
}

interface CashFlowJsonDesc {
  user: string;
  amount: number;
  currency: Currency;
}

export interface CashFlow {
  type: CashFlowType;
  date: string;
  amount: number;
  currency: Currency;
  description: string;
  wallet: string;
  jsonDesc?: CashFlowJsonDesc &
    (
      | {
          type: CashFlowType.CARD_DEPOSIT | CashFlowType.CARD_WITHDRAW;
          maskedPan: string;
        }
      | {
          type:
            | CashFlowType.TRANSACTION_FEE
            | CashFlowType.INT_TRANSACTION_FEE
            | CashFlowType.DECLINE_FEE
            | CashFlowType.TRANSACTION_REFUND
            | CashFlowType.TRANSACTION_REFUNDED_FEE
            | CashFlowType.TRANSACTION_REFUND_TO_BALANCE;
          maskedPan: string;
          txnId: number;
          desc: string;
        }
      | {
          type: CashFlowType.CARD_CLOSE;
          participant: string;
          maskedPan: string;
        }
      | {
          type: CashFlowType.FUNDS_TRANSFER;
          participant: string;
        }
      | {
          type: CashFlowType.FUNDS_DEPOSIT;
          paymentSystem?: string;
          participant?: string;
        }
      | {
          type: CashFlowType.CARD_ISSUE;
          maskedPan: string;
        }
      | {
          type: CashFlowType.INCOMING_FUNDS_TRANSFER;
          participant: string;
        }
      | {
          type: CashFlowType.FUNDS_EXCHANGE;
          fromCurrency: Currency;
          fromAmount: number;
        }
    );
}
