import axios from "axios";
import { PartnerCategory } from "@admin/types/partner";

export const updatePartnerCategory = async ({
  id,
  ...payload
}: PartnerCategory) => {
  const { data } = await axios.post<PartnerCategory>(
    "/admin/partners/category/update",
    payload,
    {
      params: {
        id,
      },
    }
  );

  return data;
};
