export enum NewsType {
  GENERAL = "GENERAL",
  BREAKING = "BREAKING",
  ATTENTION = "ATTENTION",
  TECH = "TECH",
}
export interface News {
  id: number;
  title: string;
  content: string;
  isRead: boolean;
  type: NewsType;
  date: string;
}
