import axios, { CancelToken } from "axios";
import { StatisticByType, StatisticGroupField } from "@/types/statistic";
import { Currency } from "@/types/currency";

export async function searchStatisticByType({
  cancelToken,
  ...payload
}: {
  periodStart: number;
  periodEnd: number;
  bankIds?: number[];
  cardGroupIds?: (number | null)[];
  currency?: Currency[];
  userEmails?: string[];
  teamleadEmails?: string[];
  cardIds?: number[];
  groupBy?: StatisticGroupField;
  cancelToken?: CancelToken;
}): Promise<StatisticByType> {
  const { data } = await axios.post<StatisticByType>(
    "/statistic/search",
    payload,
    {
      cancelToken,
    }
  );

  return data;
}
