import axios from "axios";

export async function confirmEmail({
  secret,
}: {
  secret: string;
}): Promise<void> {
  const { data } = await axios.post("/auth/email/confirm", { secret });

  return data;
}
