import { Action } from "vuex";
import { RootState } from "@store/types";
import { PartnerCategoryState, PartnerCategoryGetters } from "../types";
import { LangCode } from "@/types/lang";

const fetchPartnerCategories: Action<PartnerCategoryState, RootState> = async (
  { commit, getters },
  { langCode }: { parentID: number; langCode: LangCode }
) => {
  const partnerCategoriesLoadingGetter =
    getters.partnerCategoriesLoading as PartnerCategoryGetters["partnerCategoriesLoading"];
  const partnerCategoriesListGetter =
    getters.partnerCategoriesList as PartnerCategoryGetters["partnerCategoriesList"];

  const partnerCategoriesLoading = partnerCategoriesLoadingGetter(langCode);
  const partnerCategoriesList = partnerCategoriesListGetter(langCode);

  if (partnerCategoriesLoading || partnerCategoriesList.size > 0) return;

  commit("setPartnerCategoriesLoading", { langCode, value: true });

  try {
    const { fetchPartnerCategories } = await import(
      "@/api/partner/category/fetchPartnerCategories"
    );

    const partnerCategories = await fetchPartnerCategories({
      lang: langCode,
    });

    commit("setPartnerCategoriesLoading", { langCode, value: false });
    commit("setPartnerCategoriesList", {
      langCode,
      value: new Map(
        partnerCategories.map((partnerCategory) => [
          partnerCategory.id,
          partnerCategory,
        ])
      ),
    });
  } catch {
    commit("setPartnerCategoriesLoading", { langCode, value: false });
  }
};

export default fetchPartnerCategories;
