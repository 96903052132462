import axios from "axios";
import { Promocode } from "@/types/promocode";

export async function fetchProfilePromocodes(): Promise<Promocode[]> {
  const { data } = await axios.get<Promocode[]>("/profile/promocode");

  data.reverse();

  return data;
}
