import axios, { AxiosResponse } from "axios";
import { Profile } from "@/types/profile";

export function updateProfile({
  isAxiosNotify,
  ...payload
}: Partial<
  Pick<
    Profile,
    | "email"
    | "phone"
    | "telegram"
    | "notifyByEmail"
    | "notifyByTelegram"
    | "language"
    | "avatar"
  > & { isAxiosNotify: boolean }
>): Promise<AxiosResponse<Profile>> {
  if (
    typeof payload?.phone?.number === "string" &&
    !payload.phone.number.trim().length
  ) {
    payload.phone = null;
  }

  return axios.post<Profile>("/profile/update", payload, {
    isAxiosNotify,
    isAxiosRetry: false,
  });
}
