import axios, { AxiosResponse } from "axios";

export function verifyConfirmCode({
  code,
}: {
  code: string;
}): Promise<AxiosResponse> {
  return axios.post(
    "/profile/confirm/verify",
    { code },
    {
      isAxiosRetry: false,
    }
  );
}
