import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { PartnerCategoryState } from "../types";
import partnerCategoriesList from "./partnerCategoriesList";
import partnerCategoriesLoading from "./partnerCategoriesLoading";

const getters: GetterTree<PartnerCategoryState, RootState> = {
  partnerCategoriesList,
  partnerCategoriesLoading,
};

export default getters;
