import { ModuleTree } from "vuex";
import { RootState } from "@store/types";

import category from "./category";

const modules: ModuleTree<RootState> = {
  category,
};

export default modules;
