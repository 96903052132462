import { Action } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "../types";
import axios from "axios";

const fetchCardCvv: Action<CardState, RootState> = async (_, { id }) => {
  // Создаем пару ключей: приватный и публичный
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt", "decrypt"]
  );

  if (!keyPair.publicKey || !keyPair.privateKey) {
    return;
  }

  // Экспортируем публичный ключ
  const publicKey = await window.crypto.subtle.exportKey(
    "spki",
    keyPair.publicKey
  );

  // Переводим публичный ключ в формат base64
  const publicKeyBase64 = btoa(
    String.fromCharCode(...new Uint8Array(publicKey))
  );

  // Передаем в публичный ключ в API
  const { data } = await axios.post<string>("/card/cvv", {
    // id - идентификатор карты
    id,
    publicKey: publicKeyBase64,
  });

  // Расшифровываем ответ от API по приватному ключу и получаем в виде бинарного массива
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    keyPair.privateKey,
    Uint8Array.from(atob(data), (c) => c.charCodeAt(0))
  );

  // Декодируем текст из бинарного массива
  return new TextDecoder().decode(decrypted);
};

export default fetchCardCvv;
