import axios from "axios";
import { Card } from "@/types/card";

export async function withDrawCard({
  cardId,
  amount,
}: {
  cardId: number;
  amount: number;
}): Promise<Card> {
  const { data } = await axios.post<Card>(`/card/${cardId}/withdraw`, {
    amount,
  });

  return data;
}
