import axios from "axios";

interface Response {
  link: string;
  expireAt: number;
}

export async function refreshReferralInviteUrl(): Promise<Response> {
  const { data } = await axios.get<Response>("/referral/invite/refresh");

  return data;
}
