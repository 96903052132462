import axios from "axios";
import { PartnerCategory } from "@admin/types/partner";

export const createPartnerCategory = async (
  payload: Omit<PartnerCategory, "id">
) => {
  const { data } = await axios.post<PartnerCategory>(
    "/admin/partners/category/create",
    payload
  );

  return data;
};
