import { ActionTree } from "vuex";
import { PartnerCategoryState } from "../types";
import { RootState } from "@store/types";
import fetchPartnerCategories from "./fetchPartnerCategories";

const actions: ActionTree<PartnerCategoryState, RootState> = {
  fetchPartnerCategories,
};

export default actions;
