import axios from "axios";
import { PartnerCategory } from "@/types/partner";
import { LangCode } from "@/types/lang";

export async function fetchPartnerCategories({ lang }: { lang: LangCode }) {
  const { data } = await axios.get<PartnerCategory[]>(
    "/partners/category/list",
    {
      params: {
        lang,
      },
    }
  );

  return data;
}
