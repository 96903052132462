import axios from "axios";
import { CardBatchUpdateAction, CardBatchUpdate } from "@/types/card";

export async function batchUpdateCard(payload: {
  cardIds: number[];
  cardAction: CardBatchUpdateAction;
}): Promise<CardBatchUpdate> {
  const { data } = await axios.post<CardBatchUpdate>(
    "/card/batchUpdate",
    payload,
    {
      isAxiosNotify: false,
    }
  );

  return data;
}
