import { uniqCid } from "@helpers";
import forOwn from "./forOwn";

type Callback = (value: Element | PromiseLike<Element | null> | null) => void;

let intervalId = 0;
let requestAnimationFrameId = 0;
let queueHandlersCount = 0;
const queueHandlers: Record<
  string,
  {
    callback: Callback | null;
    retries: number;
    selector: string;
  }
> = {};

export default function asyncQuerySelector(
  selector: string,
  options: {
    onlyVisibled?: boolean;
    retries?: number;
  } = {}
): Promise<Element | null> {
  const { onlyVisibled = false, retries = 50 } = options;

  const intervalHandler = () => {
    window.cancelAnimationFrame(requestAnimationFrameId);

    requestAnimationFrameId = window.requestAnimationFrame(() => {
      const deletedHandlerIds: string[] = [];

      forOwn(queueHandlers, (queueHandler, handlerId) => {
        if (!queueHandler.callback) return;

        let element = document.querySelector<HTMLElement>(
          queueHandler.selector
        );

        if (
          onlyVisibled &&
          element &&
          (element.offsetHeight < 10 || element.offsetWidth < 10)
        ) {
          element = null;
        }

        if (element || queueHandler.retries <= 0) {
          queueHandler.callback(element);
          deletedHandlerIds.push(handlerId);
        } else {
          queueHandler.retries--;
        }
      });

      deletedHandlerIds.forEach((handlerId) => {
        delete queueHandlers[handlerId];
        queueHandlersCount--;
      });

      if (!queueHandlersCount) {
        window.clearInterval(intervalId);
        intervalId = 0;
      }
    });
  };

  if (!intervalId) {
    intervalId = window.setInterval(intervalHandler, 400);
  }

  const queueHandlerId = uniqCid().toString();

  queueHandlers[queueHandlerId] = {
    callback: null,
    retries,
    selector,
  };

  queueHandlersCount++;

  return new Promise<null | Element>((resolve) => {
    const queueHandler = queueHandlers[queueHandlerId];

    if (queueHandler) {
      queueHandler.callback = resolve;
      intervalHandler();
    } else {
      resolve(null);
    }
  });
}
