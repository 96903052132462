import axios from "axios";
import { MessageType } from "@/types/message";

export async function closeFeedMessages(payload: {
  ids?: string[];
  types?: MessageType[];
}) {
  const { data } = await axios.post("/feed/messages/close", payload);

  return data;
}
