export default function loadScript({ src, id }: { src: string; id?: string }) {
  return new Promise<HTMLScriptElement>((resolve, reject) => {
    const scriptFromDocument =
      id && (document.getElementById(id) as HTMLScriptElement | null);

    if (scriptFromDocument) {
      resolve(scriptFromDocument);
      return;
    }

    const script = document.createElement("script");

    if (id) {
      script.id = id;
    }

    script.async = true;
    script.src = src;
    script.nonce = "dQw4w9WgXcQ";

    document.head.prepend(script);

    const onLoad = () => {
      resolve(script);
      script.removeEventListener("load", onLoad);
    };

    const onError = () => {
      reject(null);
      script.removeEventListener("error", onError);
    };

    script.addEventListener("load", onLoad);
    script.addEventListener("error", onError);
  });
}
