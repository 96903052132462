import axios from "axios";
import { CardBatchIssue } from "@/types/card";

export async function batchIssueCardUpdate(payload: {
  id: number;
  isClosed?: boolean;
  isCanceled?: boolean;
}): Promise<CardBatchIssue> {
  const { data } = await axios.post("/card/batchIssue/update", payload);

  return data;
}
