import { Action } from "vuex";
import { RootState } from "@store/types";
import { PartnerState, PartnerGetters } from "../types";
import { LangCode } from "@/types/lang";

const fetchPartners: Action<PartnerState, RootState> = async (
  { commit, getters },
  { langCode }: { parentID: number; langCode: LangCode }
) => {
  const partnersLoadingGetter =
    getters.partnersLoading as PartnerGetters["partnersLoading"];
  const partnersListGetter =
    getters.partnersList as PartnerGetters["partnersList"];

  const partnersLoading = partnersLoadingGetter(langCode);
  const partnersList = partnersListGetter(langCode);

  if (partnersLoading || partnersList.length > 0) return;

  commit("setPartnersLoading", { langCode, value: true });

  try {
    const { fetchPartners } = await import("@/api/partner/fetchPartners");

    const partners = await fetchPartners({
      lang: langCode,
    });

    commit("setPartnersLoading", { langCode, value: false });
    commit("setPartnersList", { langCode, value: partners });
  } catch {
    commit("setPartnersLoading", { langCode, value: false });
  }
};

export default fetchPartners;
